import React, { FC, useEffect, useState } from "react";
import poster from '../images/korea/kr-a.png';
import { css, Global } from "@emotion/react";
import { Helmet } from "react-helmet";

const KoreaPage: FC = () => {
  const [link, setLink] = useState<string | null>(null)
  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1))
    setLink(params.get('link'))
  }, [])

  return (
    <div css={css`display: flex; justify-content: center;`}>
      <Global styles={css`body { margin: 0;}`} />
      <a href={link || ''} id='KR_M_BDA_INT_AND_AW_201211'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Azar</title>
        </Helmet>
        <img
          src={poster}
          alt="azar"
          css={css`height: 100vh;`}
        />
      </a>
    </div>
  )
}

export default KoreaPage
